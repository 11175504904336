import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _2739a251 = () => interopDefault(import('../pages/venues/index.vue' /* webpackChunkName: "pages/venues/index" */))
const _42600898 = () => interopDefault(import('../pages/venues/booking.vue' /* webpackChunkName: "pages/venues/booking" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _2fecb318 = () => interopDefault(import('../pages/bookings/_booking/index.vue' /* webpackChunkName: "pages/bookings/_booking/index" */))
const _05adbcda = () => interopDefault(import('../pages/bookings/_booking/dashboard.vue' /* webpackChunkName: "pages/bookings/_booking/dashboard" */))
const _46be2852 = () => interopDefault(import('../pages/bookings/_booking/payments.vue' /* webpackChunkName: "pages/bookings/_booking/payments" */))
const _aaba09b8 = () => interopDefault(import('../pages/bookings/_booking/pencilled.vue' /* webpackChunkName: "pages/bookings/_booking/pencilled" */))
const _75c2adfc = () => interopDefault(import('../pages/bookings/_booking/thank-you.vue' /* webpackChunkName: "pages/bookings/_booking/thank-you" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/venues",
    component: _2739a251,
    name: "venues"
  }, {
    path: "/venues/booking",
    component: _42600898,
    name: "venues-booking"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index"
  }, {
    path: "/bookings/:booking",
    component: _2fecb318,
    name: "bookings-booking"
  }, {
    path: "/bookings/:booking?/dashboard",
    component: _05adbcda,
    name: "bookings-booking-dashboard"
  }, {
    path: "/bookings/:booking?/payments",
    component: _46be2852,
    name: "bookings-booking-payments"
  }, {
    path: "/bookings/:booking?/pencilled",
    component: _aaba09b8,
    name: "bookings-booking-pencilled"
  }, {
    path: "/bookings/:booking?/thank-you",
    component: _75c2adfc,
    name: "bookings-booking-thank-you"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
