import VuexPersistence from "vuex-persist";
import Cookies from "js-cookie";

export default ({
  store
}) => {
  new VuexPersistence({
    /* your options */
    restoreState: (key, storage) => Cookies.getJSON(key),
    saveState: (key, state, storage) => Cookies.set(key, state),
    modules: ["Booking"],
    filter: mutation =>
      mutation.type === "Booking/setTime" ||
      mutation.type === "Booking/setVenue" ||
      mutation.type === "Booking/setDate" ||
      mutation.type === "Booking/setSchedule",
  }).plugin(store);
};
