import { GetterTree, MutationTree, ActionTree } from "vuex";
import { State } from "../interfaces/state";

const state = () => ({
  date: null,
  time: null,
  venue: null,
  schedule: null,
  customer_id: null,
  booking: null,
  price: null,
  custom_price_id: null
});

const getters = <GetterTree<State, any>>{
  getDate: (state: State) => {
    if (state.date && typeof state.date == "string") {
      return new Date(state.date);
    }
    return state.date;
  },
  getTime: (state: State) => state.time,
  getVenue: (state: State) => state.venue,
  getSchedule: (state: State) => state.schedule,
  getCustomerId: (state: State) => state.customer_id,
  getBooking: (state: State) => state.booking,
  getPrice: (state: State) => state.price,
  getCustomPriceId: (state: State) => state.custom_price_id
};

const mutations = <MutationTree<State>>{
  setDate(state: State, date: Date) {
    state.date = date;
  },
  setTime(state: State, time: string) {
    state.time = time;
  },
  setVenue(state: State, venue: object) {
    state.venue = venue;
  },
  setSchedule(state: State, schedule: object) {
    state.schedule = schedule;
  },
  setCustomerId(state: State, id: number) {
    state.customer_id = id;
  },
  setBooking(state: State, booking: object) {
    state.booking = booking;
  },
  setPrice(state: State, price: number) {
    state.price = price;
  },
  setCustomPriceId(state: State, customPriceId: number) {
    state.custom_price_id = customPriceId;
  }
};

const actions = <ActionTree<State, any>>{
  saveDate({ commit }, date: Date) {
    commit("setDate", date);
  },
  saveTime({ commit }, time: string) {
    commit("setTime", time);
  },
  saveVenue({ commit }, venue: object) {
    commit("setVenue", venue);
  },
  saveSchedule({ commit }, schedule: object) {
    commit("setSchedule", schedule);
  },
  saveCustomerId({ commit }, id: number) {
    commit("setCustomerId", id);
  },
  saveBooking({ commit }, booking: object) {
    commit("setBooking", booking);
  },
  savePrice({ commit }, price: number) {
    commit("setPrice", price);
  },
  saveCustomPriceId({ commit }, customPriceId: number) {
    commit("setCustomPriceId", customPriceId);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
