import { Middleware } from "@nuxt/types";

const signature: Middleware = async ({ route, req, $axios }) => {
  const https = process.env.NODE_ENV === "development" ? "http" : "https";
  return $axios.post(`${https}://${req.headers.host}/api/verify`, {
    referral_url: `${https}://${req.headers.host}${route.fullPath}`
  });
};

export default signature;
