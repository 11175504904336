import {Middleware} from "@nuxt/types";

const recentlyBooked: Middleware = async ({req, route, redirect, $axios}) => {
  return new Promise(async (resolve, reject) => {
    $axios
      .get(
        `${process.env.NUXT_ENV_CORE_APP_DOMAIN}/api/v1/customer/bookings/${route.params.booking}`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
          }
        }
      )
      .then(res => {
          const statuses = res.data.booking_statuses;
          let booked_recently = true;
          let max_time = 3600 * 1000; // milliseconds in an hour

          for (let i = 0; i < statuses.length; ++i) {
            if (statuses[i].status === 1) { // deposit paid status
              let date = Date.parse(statuses[i].created_at);
              let difference = Date.now() - date;
              if ((max_time - difference) < 0) {
                booked_recently = false;
              }
            }
          }

          if (booked_recently) {
            resolve();
          } else {
            // if past max_time after booking, do not allow access to thank you url anymore
            resolve(redirect(res.data.dashboard_url));
          }
        }
      )
      .catch(err => reject(err));
  });
};

export default recentlyBooked;
