import { Middleware } from "@nuxt/types";

const depositPaid: Middleware = async ({ req, route, redirect, $axios }) => {
  return new Promise(async (resolve, reject) => {
    $axios
      .get(
        `${process.env.NUXT_ENV_CORE_APP_DOMAIN}/api/v1/customer/bookings/${route.params.booking}`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
          }
        }
      )
      .then(res => {
        if (res.data.stripe_transactions.length == 0) {
          resolve(redirect(res.data.deposit_payment_url));
        } else resolve();
      })
      .catch(err => reject(err));
  });
};

export default depositPaid;
