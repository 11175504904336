import { Middleware } from "@nuxt/types";

const booking: Middleware = async ({ route, req, $axios }) => {
  return $axios.get(
    `${process.env.NUXT_ENV_CORE_APP_DOMAIN}/api/v1/customer/bookings/${route.params.booking}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    }
  );
};

export default booking;
