import merge from '~lodash.mergewith'
import { init } from '~@sentry/vue'
import * as CoreSdk from '~@sentry/core'
import * as BrowserSdk from '~@sentry/browser-sdk'
import { ExtraErrorData, ReportingObserver, RewriteFrames } from '~@sentry/integrations'

export { init }
export const SentrySdk = { ...CoreSdk, ...BrowserSdk }

export function getConfig (ctx) {
  /* eslint-disable object-curly-spacing, quote-props, quotes, key-spacing, comma-spacing */
  const config = {
    dsn:"https:\u002F\u002F5f5ac206e48548babf4d0f2e7058b7d0@o4505327205089280.ingest.sentry.io\u002F4505327211970560",
    environment:"production",
    beforeSend:function (event, hint) {
        try {
          if (event.exception.values[0].stacktrace.frames[0].filename === '<anonymous>') {
            return null;
          }

          if (event.exception.values[0].stacktrace.frames[1].module === 'gtm') {
            return null;
          }

          if (event.exception.values[0].stacktrace.frames[1].module === 'gtag/js') {
            return null;
          }

          if (event.exception.values[0].value === 'rabbit') {
            return null;
          }

          if (event.exception.values[0].value === 'Request failed with status code 403') {
            return null;
          }

          if (
          event.contexts.Error.isAxiosError &&
          event.contexts.Error.message === 'Request failed with status code 403')
          {
            return null;
          }
        } catch (e) {}

        return event;
      },
    release:"8a33fae30248a2a95027576eaa9d0c1d84985ba8",
  }

  config.integrations = [
    new ExtraErrorData(),
    new ReportingObserver({ types:["crash"] }),
    new RewriteFrames(),
  ]

  const runtimeConfigKey = "sentry"
  if (ctx.$config && runtimeConfigKey && ctx.$config[runtimeConfigKey]) {
    merge(config, ctx.$config[runtimeConfigKey].config, ctx.$config[runtimeConfigKey].clientConfig)
  }

  return config
}
