const middleware = {}

middleware['booking'] = require('../middleware/booking.ts')
middleware['booking'] = middleware['booking'].default || middleware['booking']

middleware['depositPaid'] = require('../middleware/depositPaid.ts')
middleware['depositPaid'] = middleware['depositPaid'].default || middleware['depositPaid']

middleware['recentlyBooked'] = require('../middleware/recentlyBooked.ts')
middleware['recentlyBooked'] = middleware['recentlyBooked'].default || middleware['recentlyBooked']

middleware['signature'] = require('../middleware/signature.ts')
middleware['signature'] = middleware['signature'].default || middleware['signature']

export default middleware
